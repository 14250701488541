<template>
  <l-content-widget
    sticky
    fullHeight
  >
    <template #widget>
      <l-widget
        vertical
        fullHeight
        no-ident
      >
        <template #header>
          <div class="d-xl-none w-100">
            <div class="row">
              <div class="col px-16 py-8">
                {{ info.userName }}
              </div>
              <v-divider vertical/>
              <div class="col-3 px-16 py-8">
                <b>Дата оценки:</b> {{ info.date }}
              </div>

              <v-divider vertical/>

              <div class="col-3 px-16 py-8">
                <b>Итог:</b> {{ tabsInfo.total }}/{{ tabsInfo.totalPassing }} баллов
              </div>
            </div>
          </div>

          <div class="d-none d-xl-block p-16">
            <b>{{ info.userName }}</b>
          </div>
        </template>

        <template #default>
          <div class="d-xl-none row align-items-center">
            <div class="col-9 px-16 py-8">
              <div
                class="text-truncate"
                :title="info.qualification"
              >
                <b>Квалификация:</b> {{ info.qualification }}
              </div>
            </div>

            <v-divider vertical/>

            <div class="col-3 pl-0">
              <v-button
                :color="doneBtnState.color"
                :disabled="currentStepOfTest === 2"
                full-width
                @click="doneBtnHandler"
                class="border-radius-inherit"
                style="border-bottom-right-radius: 8px"
              >
                {{ doneBtnState.text }}
              </v-button>
            </div>
          </div>

          <div class="d-none d-xl-block border-radius-inherit">
            <div class="p-16">
              <p class="mb-0 text-size-12 text-color-default"><b>Дата оценки</b></p>
              <p>{{ info.date }}</p>
              <p class="mb-0 text-size-12 text-color-default"><b>Квалификация</b></p>
              <p class="mb-0">
                {{ info.qualification }}
              </p>
            </div>

            <v-divider/>

            <v-list>
              <v-list-item
                v-for="(tab, tabIndex) in tabs"
                :key="tabIndex"
                :active="currentTabIndex === tabIndex"
                clickable
                hoverable
                @click="currentTabIndex = tabIndex"
              >
                <b>{{ tab.iconFull }}</b>
                <p class="m-0 text-color-default">Баллы: {{ tabsInfo[tab.id].total }}/{{ tab.passingScore }}</p>
              </v-list-item>

              <v-list-item
                :active="currentTabIndex === tabs.length"
                hoverable
                clickable
                @click="currentTabIndex = tabs.length"
              >
                <b>Итог: </b>
                {{ tabsInfo.total }}/{{ tabsInfo.totalPassing }} баллов
              </v-list-item>
            </v-list>

            <v-button
              :color="doneBtnState.color"
              :disabled="currentStepOfTest === 2"
              full-width
              @click="doneBtnHandler"
              class="d-none d-xl-flex border-radius-inherit"
            >
              {{ doneBtnState.text }}
            </v-button>
          </div>
        </template>
      </l-widget>
    </template>

    <template #default>
      <v-tabs
        v-model="currentTabIndex"
        size="lg"
      >
        <!-- Кнопки табов заданий -->
        <template
          v-for="(tab, tabIndex) in tabs"
          v-slot:[`slot-${tabIndex}`]="{ isActive }"
        >
          <div
            :key="tab.id"
            class="d-flex align-items-center justify-content-center"
          >
            <v-avatar
              :color="tabsInfo[tab.id].isDone ? 'success' : 'dark'"
            >
              {{ tab.icon }}
            </v-avatar>

            <span
              v-if="isActive"
              class="ml-12 text-size-16 text-nowrap"
              :class="{
                  'text-color-dark': !tabsInfo[tab.id].isDone,
                  'text-color-success': tabsInfo[tab.id].isDone,
                }"
            >
              {{ tab.iconFull }}
            </span>
          </div>
        </template>

        <!-- Кнопка таба итог -->
        <template v-slot:[`slot-${tabs.length}`]="{ isActive }">
          <div class="d-flex align-items-center justify-content-center">
            <v-icon
              sprite-name="nlmk"
              fill="primary"
              name="total"
              size="24"
            />

            <b
              v-if="isActive"
              class="ml-12 text-nowrap text-size-16"
            >
              Итог
            </b>
          </div>
        </template>

        <!-- Задания -->
        <v-tab
          v-for="tab in tabs"
          :key="tab.id"
          :id="tab.id"
          class="p-16"
        >
          <h2 class="mb-24" v-html="tab.title"/>

          <p>
            <b>Задание:</b> <span v-html="tab.text"/>
          </p>

          <p>
            <b>Время выполнения:</b> <span v-html="tab.time"/>
          </p>

          <v-table>
            <v-table-tr class="bg-color-primary text-color-light">
              <v-table-th style="width: 60%">
                Критерии оценки
              </v-table-th>
              <v-table-th>
                Фактические баллы
              </v-table-th>
              <v-table-th style="width: 40%">
                Примечание
              </v-table-th>
            </v-table-tr>

            <template
              v-for="row of tab.rows"
            >
              <v-table-tr
                :key="row.id"
                :class="{
                     'bg-color-background-7': row.type === 'title' || row.type === 'divider'
                  }"
                :error="showErrors && row.input.value === '' && row.input.type === 'select'"
                class="align-top"
              >
                <v-table-td
                  :colspan="row.type === 'divider' ? 2 : 0"
                  v-html="row.text"
                  class="text-size-14"
                />

                <v-table-td v-if="row.input">
                  <div class="row justify-content-center">
                    <div class="col-auto">
                      <v-menu
                        v-if="row.input && row.input.type === 'select'"
                        :read-only="isReadonly"
                      >
                        <template #button>
                          <v-sheet
                            :disabled="isReadonly"
                            rounded
                            outlined
                            style="width: 150px"
                          >
                            <div class="row align-items-center flex-nowrap">
                              <div class="col pr-0">
                                <div class="p-8 text-center">
                                  {{ setSelectValue(row.input.value, row.input.maxValue) }}
                                </div>
                              </div>
                              <v-divider
                                vertical
                              />
                              <div class="col-auto pl-0">
                                <v-button
                                  transparent
                                  icon
                                  size="xl"
                                >
                                  <v-icon
                                    stroke="primary"
                                    name="chevron-2"
                                    size="16"
                                    class="rotate-90"
                                  />
                                </v-button>
                              </div>
                            </div>
                          </v-sheet>
                        </template>

                        <div style="width: 257px">
                          <v-button
                            size="xl"
                            icon
                            transparent
                            v-for="option in createOptions(row.input.maxValue, row.input.customValues)"
                            :key="option.value"
                            @click="row.input.value = option.value"
                          >
                            {{ option.value }}
                          </v-button>
                        </div>
                      </v-menu>

                      <v-checkbox
                        v-else-if="row.input && row.input.type === 'checkbox'"
                        v-model="row.input.value"
                        :disabled="isReadonly"
                      />
                    </div>
                  </div>
                </v-table-td>

                <v-table-td v-if="row.comment">
                  <v-field
                    class="m-0"
                    :error="setSelectCommentError(row.input, row.comment)"
                  >
                    <v-textarea
                      v-model="row.comment.value"
                      :rows="2"
                      :placeholder="row.comment.placeholder || 'Введите примечание'"
                      :disabled="isReadonly"
                      autogrow
                    />

                    <span class="v-field__error">
                      Пожалуйста, поясните свою оценку
                    </span>
                  </v-field>
                </v-table-td>
              </v-table-tr>
            </template>

            <v-table-tr class="bg-color-background-7">
              <v-table-td><b>Сумма баллов / Проходной балл</b></v-table-td>
              <v-table-td class="text-center"><b>{{ tabsInfo[tab.id].total }}/{{ tab.passingScore }}</b></v-table-td>
              <v-table-td></v-table-td>
            </v-table-tr>
          </v-table>
        </v-tab>

        <v-tab
          id="total"
          class="p-16"
        >
          <h2 class="mb-24">Итог</h2>

          <p>
            <b>
              Нарушения, зафиксированные в ходе практической части профессионального экзамена:
            </b>
          </p>

          <v-field class="mb-40">
            <v-textarea
              v-model="total.fouls"
              :rows="4"
              :disabled="isReadonly"
              placeholder="Введите текст"
              autogrow
            />
          </v-field>

          <p>
            <b>
              Результат:
            </b>
          </p>

          <v-table class="mb-16">
            <v-table-tr class="bg-color-primary text-color-light">
              <v-table-th>
                Задания
              </v-table-th>
              <v-table-th>
                Предварительный балл
              </v-table-th>
              <v-table-th>
                Фактический балл
              </v-table-th>
              <v-table-th>
                Проходной балл
              </v-table-th>
            </v-table-tr>

            <v-table-tr
              v-for="task in tabs"
              :key="task.id"
            >
              <v-table-th>
                {{ task.title }}
              </v-table-th>
              <v-table-th>
                {{ tabsInfo[task.id].total }}
              </v-table-th>
              <v-table-th>
                {{ tabsInfo[task.id].total }}
              </v-table-th>
              <v-table-th>
                {{ task.passingScore }}
              </v-table-th>
            </v-table-tr>

            <v-table-tr class="bg-color-background-7">
              <v-table-th>
                <b>По сумме всех заданий:</b>
              </v-table-th>
              <v-table-th>
                {{ tabsInfo.total }}
              </v-table-th>
              <v-table-th>
                {{ tabsInfo.total }}
              </v-table-th>
              <v-table-th>
                {{ tabsInfo.totalPassing }}
              </v-table-th>
            </v-table-tr>
          </v-table>

          <p class="mb-40">
            Квалификация соискателя

            <span v-html="conclusion"/>

            требованиям к квалификации.
          </p>

          <p>
            <b>
              Рекомендации эксперта в случае прохождения профессионального экзамена с неудовлетворительным результатом:
            </b>
          </p>

          <v-field
            :error="tabsInfo.isDone && tabsInfo.total < tabsInfo.totalPassing && !total.recommendations"
          >
            <v-textarea
              v-model="total.recommendations"
              :rows="4"
              :disabled="isReadonly"
              placeholder="Введите текст"
              autogrow
            />

            <span class="v-field__help ">
              Обязательно к заполнению в случае прохождения профессионального экзамена с неудовлетворительным результатом
            </span>
            <span class="v-field__error">
              Обязательно к заполнению в случае прохождения профессионального экзамена с неудовлетворительным результатом
            </span>
          </v-field>
        </v-tab>
      </v-tabs>

      <v-modal
        v-model="showModal"
      >
        <h4 slot="header">Внимание!</h4>

        Вы точно хотите завершить оценку? Далее вы не сможете изменить проставленные баллы и примечания.

        <div class="row mt-40">
          <div class="col-auto">
            <v-button
              @click="doneTest"
            >
              Завершить оценку
            </v-button>
          </div>
          <div class="col-auto">
            <v-button
              color="default"
              outlined
              @click="showModal = false"
            >
              Отмена
            </v-button>
          </div>
        </div>
      </v-modal>

      <v-snackbar
        v-model="showSnackbar"
        color="danger"
      >
        <b>Пожалуйста, поставьте все оценки</b>
      </v-snackbar>
    </template>
  </l-content-widget>
</template>

<script>
import { createCustomArray, getRandomUuid } from '@utils/helpers'
import { LContentWidget, LWidget } from '@/layouts'
import {
  VAvatar,
  VButton,
  VDivider,
  VTab,
  VTable,
  VTableTd,
  VTableTh,
  VTableTr,
  VTabs
} from '@components/base'
import VField from '@components/base/VField/VField'
import VTextarea from '@components/base/VField/VTextarea/VTextarea'
import VCheckbox from '@components/base/VCheckbox/VCheckbox'
import VMenu from '@components/base/VMenu/VMenu'
import VSheet from '@components/base/VSheet/VSheet'
import VIcon from '@components/base/VIcon/VIcon'
import VModal from '@components/base/VModal/VModal'
import VSnackbar from '@components/base/VSnackbar/VSnackbar'
import VList from '@components/base/VList/VList'
import VListItem from '@components/base/VList/VListItem'

export default {
  name: 'SNlmkCOK',

  components: {
    VListItem,
    VList,
    VSnackbar,
    VModal,
    VIcon,
    VSheet,
    VMenu,
    VCheckbox,
    VTextarea,
    VField,
    VButton,
    VDivider,
    VAvatar,
    LContentWidget,
    LWidget,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr,
    VTab,
    VTabs
  },

  data () {
    return {
      isNegative: true,
      info: {
        date: '20.01.2022',
        userName: 'Константинский Константин Константинович',
        qualification: '27.01200.01 Термист проката и труб на непрерывных линиях закалки и отжига (4 уровень квалификации)'
      },
      currentTabIndex: 0,
      tabs: [
        {
          id: getRandomUuid(),
          title: 'Подготовка рулона к установке на разматыватель, установка на разматыватель, подготовка переднего конца рулона к сварке (сшивке).',
          text: null,
          time: '30 минут',
          iconFull: 'Практическое задание №1',
          icon: '1',
          passingScore: '33',
          rows: [
            {
              id: getRandomUuid(),
              text: 'Нарушены требования охраны труда при выполнении операции (опасные приемы в работе, не применение СИЗ)',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Выполнение подготовительных мероприятий не в полном объеме (не произвел осмотр состояния рулона, не произвел подготовку внутреннего витка рулона, внешнего витка) – минус 5 балла',
              type: 'question',
              input: {
                name: '2',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Не введены/не проверены данные о рулоне, подлежащем установке (задаче) на разматыватель в информационную систему – минус 7 баллов',
              type: 'question',
              input: {
                name: '3',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Нарушена последовательность действий при установке рулона на разматыватель, подготовке переднего конца полосы',
              type: 'question',
              input: {
                name: '4',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Некорректное центрирование рулона, установленного на разматыватель, относительно оси агрегата- минус 6 баллов',
              type: 'question',
              input: {
                name: '5',
                type: 'select',
                maxValue: '6',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Выполнение операции по подготовке переднего конца полосы не в полном объеме (недостаточно обрезана длина, имеются дефекты на поверхности полосы)',
              type: 'question',
              input: {
                name: '6',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Установка рулона, подготовка переднего конца полосы выполнена несвоевременно (последовало снижение скорости линии или остановка)',
              type: 'question',
              input: {
                name: '7',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Несвоевременно или некорректно предпринимает действия в случае забуривания при подготовке переднего конца полосы',
              type: 'question',
              input: {
                name: '8',
                type: 'select',
                maxValue: '6',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Нарушение технологии отбора проб металла, маркировка проб (если предусмотрено технологией)',
              type: 'question',
              input: {
                name: '8',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Невыполнение операции подготовки рулона к установке на разматыватель, установки на разматыватель, подготовки переднего конца рулона к сварке (сшивке)',
              type: 'question',
              input: {
                name: '9',
                type: 'select',
                maxValue: '33',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            }
          ]
        },
        {
          id: getRandomUuid(),
          title: 'Окончание размотки рулона, сварка (сшивка) полос, запуск входной части агрегата, подготовка разматывателя к установке на него рулона',
          text: null,
          iconFull: 'Практическое задание №2',
          time: '30 минут',
          icon: '2',
          passingScore: '33',
          rows: [
            {
              id: getRandomUuid(),
              text: 'Нарушены требования охраны труда при выполнении операции (опасные приемы в работе, не применение СИЗ)',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Невыполнение контроля окончания размотки рулона, несвоевременность остановки входной части агрегата на сварку',
              type: 'question',
              input: {
                name: '2',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Некорректное центрирование уходящей, приходящей полос в сварочной (сшивной) машине, выполнение сварного/сшивного соединения со смещением',
              type: 'question',
              input: {
                name: '3',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Некорректный выбор режима сварки (сшивки) полос',
              type: 'question',
              input: {
                name: '3',
                type: 'select',
                maxValue: '6',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Нарушение последовательности действий в процессе подачи уходящего и приходящего концов полос к сварочной (сшивной) машине, сварки/сшивки полос',
              type: 'question',
              input: {
                name: '4',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Несвоевременное выполнение сварки (сшивки) полос и запуска входной части агрегата (последовало снижение скорости линии или остановка)',
              type: 'question',
              input: {
                name: '5',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Невыполнение контроля качества сварного/сшивного соединения, либо невыявление дефектов сварного (сшивного) соединения',
              type: 'question',
              input: {
                name: '6',
                type: 'select',
                maxValue: '6',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Несвоевременное или некорректное выполнение действий в случае неудовлитворительного качества сварного (сшивного) соединения- минус 7 баллов',
              type: 'question',
              input: {
                name: '7',
                type: 'select',
                maxValue: '7',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Неверное объяснение признаков неудовлетворительного качества сварного (сшивного) соединения полос, порядка выявления несоответствия, порядка корректирующих действий',
              type: 'question',
              input: {
                name: '8',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Несвоевременное выполнение операции по обрезке заднего конца предыдущего рулона и подготовке разматывателя к установке на него рулона',
              type: 'question',
              input: {
                name: '9',
                type: 'select',
                maxValue: '4',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Выполнение операции по подготовке разматывателя не в полном объеме (не все механизмы разматывателя находятся в требуемом положении)',
              type: 'question',
              input: {
                name: '10',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Не выполнение сварки полос, запуска входной части агрегата, подготовки разматывателя к установке на него рулона',
              type: 'question',
              input: {
                name: '11',
                type: 'select',
                maxValue: '33',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            }
          ]
        }
      ],
      total: {
        fouls: '',
        recommendations: ''
      },
      showModal: false,
      showSnackbar: false,
      showErrors: false,
      doneBtnText: ['Начать оценку', 'Завершить оценку', 'Оценка завершена'],
      currentStepOfTest: 0
    }
  },

  watch: {
    currentTabIndex (newIndex) {
      localStorage.setItem('SNLMKCOK_tab-index', newIndex)
    },

    currentStepOfTest (nextStep) {
      localStorage.setItem('SNLMKCOK_test-step', nextStep)
    },

    total: {
      deep: true,
      handler () {
        localStorage.setItem('SNLMKCOK_tab-total', JSON.stringify(this.total))
      }
    }
  },

  created () {
    // !!! То что ниже должно быть в created, иначе будет перетираться из computed !!!
    // todo весь localstorage перенести в отдельный сервис
    if (localStorage.getItem('SNLMKCOK_tab-index')) {
      this.currentTabIndex = JSON.parse(localStorage.getItem('SNLMKCOK_tab-index'))
    }

    if (localStorage.getItem('SNLMKCOK_test-step')) {
      this.currentStepOfTest = JSON.parse(localStorage.getItem('SNLMKCOK_test-step'))
    }

    if (localStorage.getItem('SNLMKCOK_tab-total')) {
      this.total = JSON.parse(localStorage.getItem('SNLMKCOK_tab-total'))
    }

    if (localStorage.getItem('SNLMKCOK')) {
      this.tabs = JSON.parse(localStorage.getItem('SNLMKCOK'))
    } else {
      this.tabs.forEach(tab => {
        tab.rows
          .filter(row => row.input.type === 'select')
          .forEach(rowSelect => {
            rowSelect.comment.required = false
          })
      })
    }
  },

  computed: {
    isReadonly () {
      return this.currentStepOfTest !== 1
    },
    conclusion () {
      return this.tabsInfo.total >= this.tabsInfo.totalPassing
        ? '<b class="text-color-success">соответствует</b>'
        : '<b class="text-color-danger">не соответствует</b>'
    },

    isDoneBtnDisabled () {
      if (!this.tabsInfo.isDone) return true
      return this.tabsInfo.total < this.tabsInfo.totalPassing && !this.total.recommendations
    },

    doneBtnState () {
      return {
        text: this.doneBtnText[this.currentStepOfTest],
        color: (this.isDoneBtnDisabled && this.currentStepOfTest === 1) || this.currentStepOfTest === 2 ? 'default' : null
      }
    },

    tabsInfo () {
      localStorage.setItem('SNLMKCOK', JSON.stringify(this.tabs))

      return this.tabs.reduce((result, tab) => {
        const tabResult = tab.rows
          .filter(row => row.input.type === 'select')
          .reduce((rowResult, row) => {
            rowResult.total += +row.input.value

            if (this.isNegative && rowResult.total < 0) {
              rowResult.total = 0
            }

            if (!rowResult.isDone || row.input.value === '') {
              rowResult.isDone = false
              return rowResult
            }

            if (this.isNegative) {
              // если ответ меньше 0 и комментарий не указан
              rowResult.isDone = !(row.input.value !== 0 && !row.comment.value)
            } else {
              // если ответ меньше максимального значения и комментарий не указан
              rowResult.isDone = !(row.input.value < row.input.maxValue && !row.comment.value)
            }

            return rowResult
          }, {
            total: this.isNegative ? 33 : 0,
            totalPassing: +tab.passingScore,
            isDone: true
          })

        if (!tabResult.isDone || !result.isDone) {
          result.isDone = false
        }

        result.total += tabResult.total
        result.totalPassing += tabResult.totalPassing

        return {
          [tab.id]: tabResult,
          ...result
        }
      }, {
        total: 0,
        totalPassing: 0,
        isDone: true
      })
    }
  },

  methods: {
    setSelectCommentError (input, comment) {
      const permanentCondition = input.type === 'select' && !comment.value && input.value !== ''
      const condition2 = this.isNegative ? input.value < 0 : input.value < input.maxValue
      return permanentCondition && condition2
    },
    /**
     * Установить текущее/максимальное значение на селект задачи
     * @param {string|number} value - текущее значение
     * @param {string|number} maxValue - максимальное значение
     * @return {string} - текущее/максимальное значение
     */
    setSelectValue (value, maxValue) {
      if (this.isNegative) {
        maxValue = -maxValue
      }

      return value !== ''
        ? `${value}/${maxValue}`
        : '?'
    },
    doneTest () {
      this.currentStepOfTest = 2
      this.showModal = false
    },
    doneBtnHandler () {
      if (this.currentStepOfTest === 2) return

      if (this.currentStepOfTest === 0) {
        this.currentStepOfTest = 1
        return
      }

      if (this.isDoneBtnDisabled) {
        this.showSnackbar = true
        this.showErrors = true
      } else {
        this.showModal = true
      }
    },
    doneTestHandler () {
      delete localStorage.SNLMKCOK
      this.showModal = false
    },
    createOptions (maxValue, customValues) {
      if (customValues) return customValues

      return createCustomArray({
        length: +maxValue + 1,
        template: i => {
          if (this.isNegative) {
            i = -i
          }

          return {
            label: i,
            value: i
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

</style>
